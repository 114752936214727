var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "imgBtn" }, [
    _c("img", {
      attrs: {
        id: "btnImg",
        src: _vm.src,
        width: _vm.width,
        height: _vm.height,
      },
    }),
    _vm._v(" "),
    _c("a", { attrs: { id: "imgBtnLabel" } }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }