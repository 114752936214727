var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Origin X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.canvasSeed.corner.x,
          callback: function ($$v) {
            _vm.$set(_vm.canvasSeed.corner, "x", _vm._n($$v))
          },
          expression: "canvasSeed.corner.x",
        },
      }),
      _vm._v("\n  Origin Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.canvasSeed.corner.y,
          callback: function ($$v) {
            _vm.$set(_vm.canvasSeed.corner, "y", _vm._n($$v))
          },
          expression: "canvasSeed.corner.y",
        },
      }),
      _vm._v("\n  Width\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeWidth },
        model: {
          value: _vm.canvasSeed.size.x,
          callback: function ($$v) {
            _vm.$set(_vm.canvasSeed.size, "x", _vm._n($$v))
          },
          expression: "canvasSeed.size.x",
        },
      }),
      _vm._v("\n  Height\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeHeight },
        model: {
          value: _vm.canvasSeed.size.y,
          callback: function ($$v) {
            _vm.$set(_vm.canvasSeed.size, "y", _vm._n($$v))
          },
          expression: "canvasSeed.size.y",
        },
      }),
      _vm._v(" "),
      _c(
        "b-checkbox",
        {
          on: { input: _vm.chengeKeepAspectCheck },
          model: {
            value: _vm.canvasSeed.keepAspect,
            callback: function ($$v) {
              _vm.$set(_vm.canvasSeed, "keepAspect", $$v)
            },
            expression: "canvasSeed.keepAspect",
          },
        },
        [_vm._v("\n     Keep Aspect Ratio\n   ")]
      ),
      _c("br"),
      _vm._v("\n  Texture\n  "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.canvasSeed.textureIndex,
              expression: "canvasSeed.textureIndex",
            },
          ],
          attrs: { size: "3", id: "texturePanel" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.canvasSeed,
                  "textureIndex",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.updateSelection,
            ],
          },
        },
        _vm._l(_vm.textureManager.canvasTextures, function (texture, index) {
          return _c("option", { domProps: { value: index } }, [
            _vm._v("\n      " + _vm._s(texture.filename) + "\n    "),
          ])
        }),
        0
      ),
      _vm._v("\n  Base 64 URL"),
      _c("br"),
      _vm._v(" "),
      _c("b-input", {
        attrs: { type: "textarea" },
        on: { input: _vm.updateCanvasURL },
        model: {
          value:
            _vm.textureManager.canvasTextures[_vm.canvasSeed.textureIndex]
              .imgUrl,
          callback: function ($$v) {
            _vm.$set(
              _vm.textureManager.canvasTextures[_vm.canvasSeed.textureIndex],
              "imgUrl",
              $$v
            )
          },
          expression:
            "textureManager.canvasTextures[canvasSeed.textureIndex].imgUrl",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: _vm.textureManager.canvasTextures[_vm.canvasSeed.textureIndex]
            .imgUrl,
          width: "256px",
          height: "256px",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }