var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "keep-alive",
    [
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [
          _c(_vm.viewComponent, {
            tag: "component",
            attrs: {
              scene2d: _vm.scene2d,
              scene3d: _vm.scene3d,
              canvasManager: _vm.canvasManager,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }