var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "link",
      class: { active: _vm.isActive },
      style: _vm.textColor,
      attrs: { href: _vm.href },
      on: { click: _vm.go },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }