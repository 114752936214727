var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "controlPanel" },
    [
      _c(
        "b-tabs",
        {
          attrs: { position: "is-centered" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("b-tab-item", { attrs: { label: "Component" } }, [
            _c(
              "section",
              { attrs: { id: "component" } },
              [
                _c("img-button", {
                  attrs: {
                    label: "Circle",
                    src: _vm.circleUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addCircle.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Half Plane",
                    src: _vm.halfPlaneUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addHalfPlane.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Orbit Seed",
                    src: _vm.orbitSeedUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addOrbitSeed.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Video Input",
                    src: _vm.cameraUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addVideoOrbit.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Canvas Seed",
                    src: _vm.orbitSeedUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addCanvasSeed.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Parallel Translation",
                    src: _vm.parallelTranslationUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addParallelTranslation.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Parallel Inversions",
                    src: _vm.parallelTranslationUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addParallelInversions.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Glide Reflection",
                    src: _vm.parallelTranslationUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addGlideReflection.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Crossing Inversions",
                    src: _vm.rotationUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addCrossingInversions.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Rotation",
                    src: _vm.rotationUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addRotation.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Scaling",
                    src: _vm.scalingUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addScaling.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Two Circles",
                    src: _vm.twoCirclesUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addTwoCircles.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img-button", {
                  attrs: {
                    label: "Loxodromic",
                    src: _vm.loxodromicUrl,
                    width: "128px",
                    height: "128px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addLoxodromic.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("b-tab-item", { attrs: { label: "Scene" } }, [
            _c(
              "section",
              [
                _c("scene-object-panel", {
                  attrs: {
                    scene: _vm.scene2d,
                    canvas2d: _vm.canvasManager.canvas2d,
                    textureManager: _vm.canvasManager.textureManager,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-tab-item",
            { attrs: { label: "Render" } },
            [
              _c("render-panel", {
                attrs: {
                  scene: _vm.scene2d,
                  canvas2d: _vm.canvasManager.canvas2d,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }