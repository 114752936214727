var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "sceneObjPanel" } },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.scene.selectedObj,
              expression: "scene.selectedObj",
            },
          ],
          staticClass: "objList",
          attrs: { size: "5" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.scene,
                  "selectedObj",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.updateSelection,
            ],
          },
        },
        [
          _vm._l(_vm.scene.objects, function (objs) {
            return _vm._l(objs, function (obj) {
              return _c("option", { domProps: { value: obj } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(obj.name) +
                    " - " +
                    _vm._s(obj.id) +
                    "\n      "
                ),
              ])
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "removeDeselectPanel" } },
        [
          _c(
            "b-button",
            {
              staticClass: "buttob",
              attrs: { type: "is-primary" },
              on: { click: _vm.deleteSelectedObj },
            },
            [_vm._v("\n      Remove\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "buttob",
              attrs: { type: "is-primary" },
              on: { click: _vm.deselectObj },
            },
            [_vm._v("\n      Deselect\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.scene.selectedObj !== undefined
        ? _c(
            "b-checkbox",
            {
              model: {
                value: _vm.scene.selectedObj.isFixed,
                callback: function ($$v) {
                  _vm.$set(_vm.scene.selectedObj, "isFixed", $$v)
                },
                expression: "scene.selectedObj.isFixed",
              },
            },
            [_vm._v("\n    Fix\n  ")]
          )
        : _vm._e(),
      _c("br"),
      _vm._v(" "),
      _vm.selectedObjName === "Circle"
        ? _c("circle-controller", {
            attrs: { circle: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "HalfPlane"
        ? _c("half-plane-controller", {
            attrs: { "half-plane": _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "OrbitSeed"
        ? _c("orbit-seed-controller", {
            attrs: {
              orbitSeed: _vm.scene.selectedObj,
              scene: _vm.scene,
              canvas: _vm.canvas2d,
              textureManager: _vm.textureManager,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "VideoOrbit"
        ? _c("video-orbit-controller", {
            attrs: { videoOrbit: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "Loxodromic"
        ? _c("loxodromic-controller", {
            attrs: { loxodromic: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "ParallelTranslation"
        ? _c("parallel-translation-controller", {
            attrs: {
              parallelTranslation: _vm.scene.selectedObj,
              scene: _vm.scene,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "ParallelInversions"
        ? _c("parallel-inversions-controller", {
            attrs: {
              parallelInversions: _vm.scene.selectedObj,
              scene: _vm.scene,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "GlideReflection"
        ? _c("glide-reflection-controller", {
            attrs: { glideReflection: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "CrossingInversions"
        ? _c("crossing-inversions-controller", {
            attrs: {
              crossingInversions: _vm.scene.selectedObj,
              scene: _vm.scene,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "Rotation"
        ? _c("rotation-controller", {
            attrs: { rotation: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "TwoCircles"
        ? _c("two-circles-controller", {
            attrs: { twoCircles: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "Scaling"
        ? _c("scaling-controller", {
            attrs: { scaling: _vm.scene.selectedObj, scene: _vm.scene },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObjName === "CanvasSeed"
        ? _c("canvas-seed-controller", {
            attrs: {
              canvasSeed: _vm.scene.selectedObj,
              scene: _vm.scene,
              canvas: _vm.canvas2d,
              textureManager: _vm.textureManager,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }