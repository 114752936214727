var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "renderPanel" }, [
    _c(
      "section",
      [
        _c(
          "b-field",
          { attrs: { label: "Backgroud Color" } },
          [
            _c("b-colorpicker", {
              attrs: { alpha: true, "color-formatter": _vm.colorFormatter },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.selectedColor,
                callback: function ($$v) {
                  _vm.selectedColor = $$v
                },
                expression: "selectedColor",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }