var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "root" } },
    [
      _vm.display
        ? _c("header-panel", { attrs: { canvasManager: _vm.canvasManager } })
        : _vm._e(),
      _vm._v(" "),
      _c("middle-panel", {
        attrs: {
          scene2d: _vm.scene2d,
          scene3d: _vm.scene3d,
          canvasManager: _vm.canvasManager,
          currentRoute: _vm.currentRoute,
        },
      }),
      _vm._v(" "),
      _vm.display ? _c("footer-panel") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }