var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { attrs: { id: "left" } }, [
        _c(
          "a",
          {
            attrs: {
              id: "ghLink",
              href: "https://github.com/soma-arc/SchottkyLink",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "imgLink",
              attrs: { src: require("../img/GitHub-Mark-32px.png") },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "version" } }, [_vm._v("Version 3.7.2 ")]),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("\n    Created by soma_arc\n  ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }