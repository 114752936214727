var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Center X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.centerChanged },
        model: {
          value: _vm.scaling.center.x,
          callback: function ($$v) {
            _vm.$set(_vm.scaling.center, "x", _vm._n($$v))
          },
          expression: "scaling.center.x",
        },
      }),
      _vm._v("\n  Center Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.centerChanged },
        model: {
          value: _vm.scaling.center.y,
          callback: function ($$v) {
            _vm.$set(_vm.scaling.center, "y", _vm._n($$v))
          },
          expression: "scaling.center.y",
        },
      }),
      _vm._v("\n  C1 Radius\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.scaling.c1.r,
          callback: function ($$v) {
            _vm.$set(_vm.scaling.c1, "r", _vm._n($$v))
          },
          expression: "scaling.c1.r",
        },
      }),
      _vm._v("\n  C2 Radius\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.scaling.c2.r,
          callback: function ($$v) {
            _vm.$set(_vm.scaling.c2, "r", _vm._n($$v))
          },
          expression: "scaling.c2.r",
        },
      }),
      _vm._v("\n  Rotation Angle\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "1" },
        on: { input: _vm.angleChanged },
        model: {
          value: _vm.scaling.rotationAngleDeg,
          callback: function ($$v) {
            _vm.$set(_vm.scaling, "rotationAngleDeg", _vm._n($$v))
          },
          expression: "scaling.rotationAngleDeg",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }