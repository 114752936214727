var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  C1 Center X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c1.center.x,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c1.center, "x", _vm._n($$v))
          },
          expression: "loxodromic.c1.center.x",
        },
      }),
      _vm._v("\n  C1 Center Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c1.center.y,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c1.center, "y", _vm._n($$v))
          },
          expression: "loxodromic.c1.center.y",
        },
      }),
      _vm._v("\n  C1 Radius\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c1.r,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c1, "r", _vm._n($$v))
          },
          expression: "loxodromic.c1.r",
        },
      }),
      _vm._v("\n  C2 Center X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c2.center.x,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c2.center, "x", _vm._n($$v))
          },
          expression: "loxodromic.c2.center.x",
        },
      }),
      _vm._v("\n  C2 Center Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c2.center.y,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c2.center, "y", _vm._n($$v))
          },
          expression: "loxodromic.c2.center.y",
        },
      }),
      _vm._v("\n  C2 Radius\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.c2.r,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.c2, "r", _vm._n($$v))
          },
          expression: "loxodromic.c2.r",
        },
      }),
      _vm._v("\n  Point X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.p.x,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.p, "x", _vm._n($$v))
          },
          expression: "loxodromic.p.x",
        },
      }),
      _vm._v("\n  Point Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.loxodromic.p.y,
          callback: function ($$v) {
            _vm.$set(_vm.loxodromic.p, "y", _vm._n($$v))
          },
          expression: "loxodromic.p.y",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }