var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Origin X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.halfPlane.p.x,
          callback: function ($$v) {
            _vm.$set(_vm.halfPlane.p, "x", _vm._n($$v))
          },
          expression: "halfPlane.p.x",
        },
      }),
      _vm._v("\n  Origin Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.halfPlane.p.y,
          callback: function ($$v) {
            _vm.$set(_vm.halfPlane.p, "y", _vm._n($$v))
          },
          expression: "halfPlane.p.y",
        },
      }),
      _vm._v("\n  Normal Angle\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "1" },
        on: { input: _vm.computeNormal },
        model: {
          value: _vm.halfPlane.normalAngleDeg,
          callback: function ($$v) {
            _vm.$set(_vm.halfPlane, "normalAngleDeg", _vm._n($$v))
          },
          expression: "halfPlane.normalAngleDeg",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }