var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/home/soma/work/js/SchottkyLink/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["src/shaders/color.njk.frag"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "const vec3 BLACK = vec3(0);\nconst vec3 WHITE = vec3(1);\nconst vec3 GRAY = vec3(0.78);\nconst vec3 RED = vec3(0.8, 0, 0);\nconst vec3 GREEN = vec3(0, 0.8, 0);\nconst vec3 BLUE = vec3(0, 0, 0.8);\nconst vec3 YELLOW = vec3(1, 1, 0);\nconst vec3 PINK = vec3(.78, 0, .78);\nconst vec3 LIGHT_BLUE = vec3(0, 1, 1);\n\nvec3 hsv2rgb(float h, float s, float v){\n    vec3 c = vec3(h, s, v);\n    const vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);\n    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);\n    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);\n}\n\n// front to back blend\nvec4 blendCol(vec4 srcC, vec4 outC){\n\tsrcC.rgb *= srcC.a;\n\treturn outC + srcC * (1.0 - outC.a);\n}\n\nconst float GAMMA = 2.2;\nconst float DISPLAY_GAMMA_COEFF = 1. / GAMMA;\nvec4 gammaCorrect(vec4 rgba) {\n    return vec4((min(pow(rgba.r, DISPLAY_GAMMA_COEFF), 1.)),\n                (min(pow(rgba.g, DISPLAY_GAMMA_COEFF), 1.)),\n                (min(pow(rgba.b, DISPLAY_GAMMA_COEFF), 1.)),\n                rgba.a);\n}\n\nvec4 deGamma(vec4 rgba) {\n    return vec4((min(pow(rgba.r, GAMMA), 1.)),\n                (min(pow(rgba.g, GAMMA), 1.)),\n                (min(pow(rgba.b, GAMMA), 1.)),\n                rgba.a);\n}\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["src/shaders/color.njk.frag"] , dependencies)