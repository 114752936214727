var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Center X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.circle.center.x,
          callback: function ($$v) {
            _vm.$set(_vm.circle.center, "x", _vm._n($$v))
          },
          expression: "circle.center.x",
        },
      }),
      _vm._v("\n  Center Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.circle.center.y,
          callback: function ($$v) {
            _vm.$set(_vm.circle.center, "y", _vm._n($$v))
          },
          expression: "circle.center.y",
        },
      }),
      _vm._v("\n  Radius\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.circle.r,
          callback: function ($$v) {
            _vm.$set(_vm.circle, "r", _vm._n($$v))
          },
          expression: "circle.r",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }