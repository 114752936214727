var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "controlPanel" },
    [
      _c(
        "b-tabs",
        {
          attrs: { position: "is-centered" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "ccc" } },
            [
              _c("b-tab-item", { attrs: { label: "Component" } }, [
                _c("section", { attrs: { id: "component" } }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-tab-item", { attrs: { label: "Scene" } }, [_c("section")]),
          _vm._v(" "),
          _c("b-tab-item", { attrs: { label: "Render" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }