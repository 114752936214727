<template>
</template>

<script>
import ControlPanel3d from './controlPanel3d.vue';

export default {
    components: { ControlPanel3d }
}
</script>

<style>
</style>
