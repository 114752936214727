var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Origin X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.parallelTranslation.p.x,
          callback: function ($$v) {
            _vm.$set(_vm.parallelTranslation.p, "x", _vm._n($$v))
          },
          expression: "parallelTranslation.p.x",
        },
      }),
      _vm._v("\n  Origin Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.parallelTranslation.p.y,
          callback: function ($$v) {
            _vm.$set(_vm.parallelTranslation.p, "y", _vm._n($$v))
          },
          expression: "parallelTranslation.p.y",
        },
      }),
      _vm._v("\n  Normal Angle\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "1" },
        on: { input: _vm.computeNormal },
        model: {
          value: _vm.parallelTranslation.normalAngleDeg,
          callback: function ($$v) {
            _vm.$set(_vm.parallelTranslation, "normalAngleDeg", _vm._n($$v))
          },
          expression: "parallelTranslation.normalAngleDeg",
        },
      }),
      _vm._v("\n  Distance\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.updateTranslation },
        model: {
          value: _vm.parallelTranslation.planeDist,
          callback: function ($$v) {
            _vm.$set(_vm.parallelTranslation, "planeDist", _vm._n($$v))
          },
          expression: "parallelTranslation.planeDist",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }