var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/home/soma/work/js/SchottkyLink/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["src/shaders/2dUniforms.njk.frag"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "uniform sampler2D u_accTexture;\nuniform vec2 u_resolution;\n// [translateX, translateY, scale]\nuniform vec3 u_geometry;\nuniform int u_maxIISIterations;\nuniform sampler2D u_videoTexture;\nuniform sampler2D u_imageTextures[";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "maxNumImageTextures"), env.opts.autoescape);
output += "];\nuniform sampler2D u_canvasTextures[";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "maxNumCanvasTextures"), env.opts.autoescape);
output += "];\nuniform float u_isRenderingGenerator;\nuniform bool u_isPressingShift;\nuniform bool u_isPressingCtrl;\n\nstruct Circle {\n    vec4 centerAndRadius; // [x, y, r, r * r]\n    float ui; // [boundaryThickness]\n    bool selected;\n};\n\nstruct HalfPlane {\n    vec2 p;\n    vec4 normal; //[x, y, normal ring radius, point radius]\n    bool selected;\n};\n\nstruct ParallelTranslation {\n    vec2 p;\n    vec4 normal; //[x, y, halfplane distance, translation length]\n    vec2 ui; //[normal ring radius, point radius]\n    bool selected;\n};\n\nstruct ParallelInversions {\n    vec2 p;\n    vec4 normal; //[x, y, halfplane distance, translation length]\n    vec2 ui; //[normal ring radius, point radius]\n    bool selected;\n};\n\nstruct CrossingInversions {\n    vec2 p;\n    vec4 normal; // [normal1, normal2]\n    vec4 boundaryPoint; // [p1, p2]\n    vec2 ui; //[normal ring radius, point radius]\n    bool selected;\n};\n\nstruct Rotation {\n    vec2 p;\n    vec4 normal; // [normal1, normal2]\n    vec4 boundaryPoint; // [p1, p2]\n    vec2 ui; //[normal ring radius, point radius]\n    bool selected;\n    float rotationRad;\n};\n\nstruct TwoCircles {\n    vec4 c1;\n    vec4 c2;\n    vec4 c1d;\n    float ui; //[boundaryThickness]\n    bool selected;\n};\n\nstruct Loxodromic {\n    vec4 c1;\n    vec4 c2;\n    vec4 c1d;\n    vec4 c3;\n    vec2 p;\n    vec4 line; // [dirX, dirY, normalX, normalY]\n    vec3 ui; //[normal ring radius, point radius, circumferenceThickness]\n    bool selected;\n};\n\nstruct OrbitSeed {\n    vec2 corner;\n    vec2 size;\n    vec4 ui; // [bodyCorner, bodySize]\n    bool selected;\n};\n\nstruct Scaling {\n    vec4 c1;\n    vec4 c2;\n    vec4 c1d;\n    vec4 line1;\n    vec4 line2;\n    vec2 ui; // [pointRadius, lineWidth]\n    bool selected;\n};\n\n//[x, y, r, r * r]\n";
frame = frame.push();
var t_3 = (lineno = 92, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircle")]));
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("n", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\nuniform Circle u_circle";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n//[x, y, r, r * r]\n";
frame = frame.push();
var t_7 = (lineno = 97, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircleFromPoints")]));
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("n", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\nuniform Circle u_circleFromPoints";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_11 = (lineno = 101, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numHalfPlane")]));
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("n", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\nuniform HalfPlane u_halfPlane";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n//[x, y, r]\n";
frame = frame.push();
var t_15 = (lineno = 106, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numPoint")]));
if(t_15) {t_15 = runtime.fromIterator(t_15);
var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("n", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\nuniform vec3 u_point";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_19 = (lineno = 110, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelTranslation")]));
if(t_19) {t_19 = runtime.fromIterator(t_19);
var t_18 = t_19.length;
for(var t_17=0; t_17 < t_19.length; t_17++) {
var t_20 = t_19[t_17];
frame.set("n", t_20);
frame.set("loop.index", t_17 + 1);
frame.set("loop.index0", t_17);
frame.set("loop.revindex", t_18 - t_17);
frame.set("loop.revindex0", t_18 - t_17 - 1);
frame.set("loop.first", t_17 === 0);
frame.set("loop.last", t_17 === t_18 - 1);
frame.set("loop.length", t_18);
output += "\nuniform ParallelTranslation u_translate";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_23 = (lineno = 114, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelInversions")]));
if(t_23) {t_23 = runtime.fromIterator(t_23);
var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("n", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "\nuniform ParallelInversions u_parallelInversions";
output += runtime.suppressValue(t_24, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_27 = (lineno = 118, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numGlideReflection")]));
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("n", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "\nuniform ParallelTranslation u_glideReflection";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_31 = (lineno = 122, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCrossingInversions")]));
if(t_31) {t_31 = runtime.fromIterator(t_31);
var t_30 = t_31.length;
for(var t_29=0; t_29 < t_31.length; t_29++) {
var t_32 = t_31[t_29];
frame.set("n", t_32);
frame.set("loop.index", t_29 + 1);
frame.set("loop.index0", t_29);
frame.set("loop.revindex", t_30 - t_29);
frame.set("loop.revindex0", t_30 - t_29 - 1);
frame.set("loop.first", t_29 === 0);
frame.set("loop.last", t_29 === t_30 - 1);
frame.set("loop.length", t_30);
output += "\nuniform CrossingInversions u_crossingInversions";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_35 = (lineno = 126, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numRotation")]));
if(t_35) {t_35 = runtime.fromIterator(t_35);
var t_34 = t_35.length;
for(var t_33=0; t_33 < t_35.length; t_33++) {
var t_36 = t_35[t_33];
frame.set("n", t_36);
frame.set("loop.index", t_33 + 1);
frame.set("loop.index0", t_33);
frame.set("loop.revindex", t_34 - t_33);
frame.set("loop.revindex0", t_34 - t_33 - 1);
frame.set("loop.first", t_33 === 0);
frame.set("loop.last", t_33 === t_34 - 1);
frame.set("loop.length", t_34);
output += "\nuniform Rotation u_rotation";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_39 = (lineno = 130, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numTwoCircles")]));
if(t_39) {t_39 = runtime.fromIterator(t_39);
var t_38 = t_39.length;
for(var t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37];
frame.set("n", t_40);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
output += "\nuniform TwoCircles u_hyperbolic";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_43 = (lineno = 134, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numLoxodromic")]));
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("n", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\nuniform Loxodromic u_loxodromic";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_47 = (lineno = 138, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numOrbitSeed")]));
if(t_47) {t_47 = runtime.fromIterator(t_47);
var t_46 = t_47.length;
for(var t_45=0; t_45 < t_47.length; t_45++) {
var t_48 = t_47[t_45];
frame.set("n", t_48);
frame.set("loop.index", t_45 + 1);
frame.set("loop.index0", t_45);
frame.set("loop.revindex", t_46 - t_45);
frame.set("loop.revindex0", t_46 - t_45 - 1);
frame.set("loop.first", t_45 === 0);
frame.set("loop.last", t_45 === t_46 - 1);
frame.set("loop.length", t_46);
output += "\nuniform OrbitSeed u_orbitSeed";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_51 = (lineno = 142, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numVideoOrbit")]));
if(t_51) {t_51 = runtime.fromIterator(t_51);
var t_50 = t_51.length;
for(var t_49=0; t_49 < t_51.length; t_49++) {
var t_52 = t_51[t_49];
frame.set("n", t_52);
frame.set("loop.index", t_49 + 1);
frame.set("loop.index0", t_49);
frame.set("loop.revindex", t_50 - t_49);
frame.set("loop.revindex0", t_50 - t_49 - 1);
frame.set("loop.first", t_49 === 0);
frame.set("loop.last", t_49 === t_50 - 1);
frame.set("loop.length", t_50);
output += "\nuniform OrbitSeed u_videoOrbit";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_55 = (lineno = 146, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numScaling")]));
if(t_55) {t_55 = runtime.fromIterator(t_55);
var t_54 = t_55.length;
for(var t_53=0; t_53 < t_55.length; t_53++) {
var t_56 = t_55[t_53];
frame.set("n", t_56);
frame.set("loop.index", t_53 + 1);
frame.set("loop.index0", t_53);
frame.set("loop.revindex", t_54 - t_53);
frame.set("loop.revindex0", t_54 - t_53 - 1);
frame.set("loop.first", t_53 === 0);
frame.set("loop.last", t_53 === t_54 - 1);
frame.set("loop.length", t_54);
output += "\nuniform Scaling u_scaling";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\n";
frame = frame.push();
var t_59 = (lineno = 150, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCanvasSeed")]));
if(t_59) {t_59 = runtime.fromIterator(t_59);
var t_58 = t_59.length;
for(var t_57=0; t_57 < t_59.length; t_57++) {
var t_60 = t_59[t_57];
frame.set("n", t_60);
frame.set("loop.index", t_57 + 1);
frame.set("loop.index0", t_57);
frame.set("loop.revindex", t_58 - t_57);
frame.set("loop.revindex0", t_58 - t_57 - 1);
frame.set("loop.first", t_57 === 0);
frame.set("loop.last", t_57 === t_58 - 1);
frame.set("loop.length", t_58);
output += "\nuniform OrbitSeed u_canvasSeed";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ";\n";
;
}
}
frame = frame.pop();
output += "\n\nuniform vec2 u_orbitOrigin;\nuniform vec4 u_backgroundColor;\nuniform vec3 u_generatorBoundaryColor;\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["src/shaders/2dUniforms.njk.frag"] , dependencies)