var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-switch",
        {
          model: {
            value: _vm.scene.videoManager.streaming,
            callback: function ($$v) {
              _vm.$set(_vm.scene.videoManager, "streaming", $$v)
            },
            expression: "scene.videoManager.streaming",
          },
        },
        [_vm._v("\n    Streaming\n  ")]
      ),
      _c("br"),
      _vm._v("\n    Origin X\n    "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.videoOrbit.corner.x,
          callback: function ($$v) {
            _vm.$set(_vm.videoOrbit.corner, "x", _vm._n($$v))
          },
          expression: "videoOrbit.corner.x",
        },
      }),
      _vm._v("\n    Origin Y\n    "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.videoOrbit.corner.y,
          callback: function ($$v) {
            _vm.$set(_vm.videoOrbit.corner, "y", _vm._n($$v))
          },
          expression: "videoOrbit.corner.y",
        },
      }),
      _vm._v("\n    Width\n    "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeWidth },
        model: {
          value: _vm.videoOrbit.size.x,
          callback: function ($$v) {
            _vm.$set(_vm.videoOrbit.size, "x", _vm._n($$v))
          },
          expression: "videoOrbit.size.x",
        },
      }),
      _vm._v("\n    Height\n    "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeHeight },
        model: {
          value: _vm.videoOrbit.size.y,
          callback: function ($$v) {
            _vm.$set(_vm.videoOrbit.size, "y", _vm._n($$v))
          },
          expression: "videoOrbit.size.y",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }