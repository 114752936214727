var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v("\n  Origin X\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.orbitSeed.corner.x,
          callback: function ($$v) {
            _vm.$set(_vm.orbitSeed.corner, "x", _vm._n($$v))
          },
          expression: "orbitSeed.corner.x",
        },
      }),
      _vm._v("\n  Origin Y\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.valueChanged },
        model: {
          value: _vm.orbitSeed.corner.y,
          callback: function ($$v) {
            _vm.$set(_vm.orbitSeed.corner, "y", _vm._n($$v))
          },
          expression: "orbitSeed.corner.y",
        },
      }),
      _vm._v("\n  Width\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeWidth },
        model: {
          value: _vm.orbitSeed.size.x,
          callback: function ($$v) {
            _vm.$set(_vm.orbitSeed.size, "x", _vm._n($$v))
          },
          expression: "orbitSeed.size.x",
        },
      }),
      _vm._v("\n  Height\n  "),
      _c("b-input", {
        attrs: { placeholder: "Number", type: "number", step: "0.01" },
        on: { input: _vm.changeHeight },
        model: {
          value: _vm.orbitSeed.size.y,
          callback: function ($$v) {
            _vm.$set(_vm.orbitSeed.size, "y", _vm._n($$v))
          },
          expression: "orbitSeed.size.y",
        },
      }),
      _vm._v(" "),
      _c(
        "b-checkbox",
        {
          on: { input: _vm.chengeKeepAspectCheck },
          model: {
            value: _vm.orbitSeed.keepAspect,
            callback: function ($$v) {
              _vm.$set(_vm.orbitSeed, "keepAspect", $$v)
            },
            expression: "orbitSeed.keepAspect",
          },
        },
        [_vm._v("\n     Keep Aspect Ratio\n   ")]
      ),
      _c("br"),
      _vm._v("\n  Texture\n  "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.orbitSeed.textureIndex,
              expression: "orbitSeed.textureIndex",
            },
          ],
          attrs: { size: "3", id: "texturePanel" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.orbitSeed,
                  "textureIndex",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.updateSelection,
            ],
          },
        },
        _vm._l(_vm.textureManager.textures, function (texture, index) {
          return _c("option", { domProps: { value: index } }, [
            _vm._v("\n      " + _vm._s(texture.filename) + "\n    "),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "b-button",
        { attrs: { type: "is-primary" }, on: { click: _vm.loadTexture } },
        [_vm._v("\n    Load Texture\n  ")]
      ),
      _vm._v(" "),
      _c("img", {
        attrs: { src: _vm.orbitTexture, width: "256px", height: "256px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }