var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["./2dUniforms.njk.frag"] = require( "./2dUniforms.njk.frag" );
dependencies["./color.njk.frag"] = require( "./color.njk.frag" );




var shim = require("/home/soma/work/js/SchottkyLink/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["src/shaders/2dShader.njk.frag"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "#version 300 es\n\nprecision mediump float;\n\nout vec4 outColor;\n\n";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("./2dUniforms.njk.frag", false, "src/shaders/2dShader.njk.frag", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n\n// include Color constants, hsv2rgb, and blendCol\n";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("./color.njk.frag", false, "src/shaders/2dShader.njk.frag", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n\nconst int NUM_ORBIT_POINTS = 10;\nvec2 g_orbitPoints[NUM_ORBIT_POINTS];\n\n// from Syntopia http://blog.hvidtfeldts.net/index.php/2015/01/path-tracing-3d-fractals/\nvec2 rand2n(const vec2 co, const float sampleIndex) {\n    vec2 seed = co * (sampleIndex + 1.0);\n    seed+=vec2(-1,1);\n    // implementation based on: lumina.sourceforge.net/Tutorials/Noise.html\n    return vec2(fract(sin(dot(seed.xy ,vec2(12.9898,78.233))) * 43758.5453),\n                fract(cos(dot(seed.xy ,vec2(4.898,7.23))) * 23421.631));\n}\n\n// circle [x, y, radius, radius * radius]\nvec2 circleInvert(const vec2 pos, const vec4 circle){\n    vec2 p = pos - circle.xy;\n    float d = length(p);\n    return (p * circle.w)/(d * d) + circle.xy;\n}\n\nvec3 computeColor(float loopNum) {\n    return hsv2rgb(0.01 + 0.05 * (loopNum -1.), 1., 1.);\n}\n\nconst int MAX_ITERATIONS = 200;\nbool IIS(vec2 pos, out vec4 col) {\n    float invNum = 0.;\n    bool inFund = true;\n    vec4 c = vec4(0);\n    for (int i = 0; i < MAX_ITERATIONS; i++) {\n        if(i > u_maxIISIterations) break;\n        inFund = true;\n\n        ";
frame = frame.push();
var t_11 = (lineno = 43, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCanvasSeed")]));
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("no", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n        vec2 canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += " = (pos - u_canvasSeed";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".corner) / u_canvasSeed";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".size;\n        if(0. < canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".x && canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".x < 1. &&\n           0. < canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".y && canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".y < 1.) {\n            //c = deGamma(textureLod(u_imageTextures[";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "CanvasSeedTexIndexes")),t_12), env.opts.autoescape);
output += "], vec2(canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".x, 1. - canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".y), 0.0));\n            c = deGamma(textureLod(u_canvasTextures[0], vec2(canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".x, 1. - canvasUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += ".y), 0.0));\n            if(c.w == 1.) {\n                col = vec4(c.rgb, 1);\n                return true;\n            }\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_15 = (lineno = 56, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numOrbitSeed")]));
if(t_15) {t_15 = runtime.fromIterator(t_15);
var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("no", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n        vec2 uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += " = (pos - u_orbitSeed";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".corner) / u_orbitSeed";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".size;\n        if(0. < uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".x && uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".x < 1. &&\n           0. < uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".y && uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".y < 1.) {\n            c = deGamma(textureLod(u_imageTextures[";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "OrbitSeedTexIndexes")),t_16), env.opts.autoescape);
output += "], vec2(uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".x, 1. - uv";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += ".y), 0.0));\n            if(c.w == 1.) {\n                col = vec4(c.rgb, 1);\n                return true;\n            }\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_19 = (lineno = 68, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numVideoOrbit")]));
if(t_19) {t_19 = runtime.fromIterator(t_19);
var t_18 = t_19.length;
for(var t_17=0; t_17 < t_19.length; t_17++) {
var t_20 = t_19[t_17];
frame.set("no", t_20);
frame.set("loop.index", t_17 + 1);
frame.set("loop.index0", t_17);
frame.set("loop.revindex", t_18 - t_17);
frame.set("loop.revindex0", t_18 - t_17 - 1);
frame.set("loop.first", t_17 === 0);
frame.set("loop.last", t_17 === t_18 - 1);
frame.set("loop.length", t_18);
output += "\n        vec2 videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += " = (pos - u_videoOrbit";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".corner) / u_videoOrbit";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".size;\n        if(0. < videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".x && videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".x < 1. &&\n           0. < videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".y && videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".y < 1.) {\n            c = deGamma(textureLod(u_videoTexture, vec2(videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".x, 1. - videoUV";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "n"), env.opts.autoescape);
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += ".y), 0.0));\n            if(c.w == 1.) {\n                col = vec4(c.rgb, 1);\n                return true;\n            }\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_23 = (lineno = 80, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircle")]));
if(t_23) {t_23 = runtime.fromIterator(t_23);
var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("n", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "\n        if(distance(pos, u_circle";
output += runtime.suppressValue(t_24, env.opts.autoescape);
output += ".centerAndRadius.xy) < u_circle";
output += runtime.suppressValue(t_24, env.opts.autoescape);
output += ".centerAndRadius.z){\n            pos = circleInvert(pos, u_circle";
output += runtime.suppressValue(t_24, env.opts.autoescape);
output += ".centerAndRadius);\n            inFund = false;\n            invNum++;\n            continue;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_27 = (lineno = 89, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircleFromPoints")]));
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("n", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "\n        if(distance(pos, u_circleFromPoints";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += ".centerAndRadius.xy) < u_circleFromPoints";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += ".centerAndRadius.z){\n            pos = circleInvert(pos, u_circleFromPoints";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += ".centerAndRadius);\n            inFund = false;\n            invNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_31 = (lineno = 97, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numHalfPlane")]));
if(t_31) {t_31 = runtime.fromIterator(t_31);
var t_30 = t_31.length;
for(var t_29=0; t_29 < t_31.length; t_29++) {
var t_32 = t_31[t_29];
frame.set("n", t_32);
frame.set("loop.index", t_29 + 1);
frame.set("loop.index0", t_29);
frame.set("loop.revindex", t_30 - t_29);
frame.set("loop.revindex0", t_30 - t_29 - 1);
frame.set("loop.first", t_29 === 0);
frame.set("loop.last", t_29 === t_30 - 1);
frame.set("loop.length", t_30);
output += "\n        pos -= u_halfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ".p;\n        float dHalfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += " = dot(pos, u_halfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ".normal.xy);\n        invNum += (dHalfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += " < 0.) ? 1. : 0.;\n        inFund = (dHalfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dHalfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ") * u_halfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ".normal.xy;\n        pos += u_halfPlane";
output += runtime.suppressValue(t_32, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_35 = (lineno = 106, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelTranslation")]));
if(t_35) {t_35 = runtime.fromIterator(t_35);
var t_34 = t_35.length;
for(var t_33=0; t_33 < t_35.length; t_33++) {
var t_36 = t_35[t_33];
frame.set("n", t_36);
frame.set("loop.index", t_33 + 1);
frame.set("loop.index0", t_33);
frame.set("loop.revindex", t_34 - t_33);
frame.set("loop.revindex0", t_34 - t_33 - 1);
frame.set("loop.first", t_33 === 0);
frame.set("loop.last", t_33 === t_34 - 1);
frame.set("loop.length", t_34);
output += "\n        pos -= u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".p;\n        float hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += " = dot(u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += " < 0. || u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".normal.z < hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ") {\n            invNum += abs(floor(hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += " / u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".normal.z));\n            pos -= u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".normal.xy * (hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += " - mod(hpd";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ", u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".normal.w/2.));\n\n            inFund = false;\n        }\n        pos += u_translate";
output += runtime.suppressValue(t_36, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_39 = (lineno = 118, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelInversions")]));
if(t_39) {t_39 = runtime.fromIterator(t_39);
var t_38 = t_39.length;
for(var t_37=0; t_37 < t_39.length; t_37++) {
var t_40 = t_39[t_37];
frame.set("n", t_40);
frame.set("loop.index", t_37 + 1);
frame.set("loop.index0", t_37);
frame.set("loop.revindex", t_38 - t_37);
frame.set("loop.revindex0", t_38 - t_37 - 1);
frame.set("loop.first", t_37 === 0);
frame.set("loop.last", t_37 === t_38 - 1);
frame.set("loop.length", t_38);
output += "\n        pos -= u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".p;\n        float hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += " = dot(u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += " < 0. || u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.z < hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ") {\n            invNum += abs(floor(hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += " / u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.z));\n            pos -= u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy * (hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += " - mod(hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ", u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.w));\n            pos -= u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.z;\n            hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += " = dot(pos, u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy);\n            pos -= 2.0 * max(0., hpdInv";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ") * u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy;\n            pos += u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".normal.z;\n\n            inFund = false;\n        }\n        pos += u_parallelInversions";
output += runtime.suppressValue(t_40, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_43 = (lineno = 134, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numGlideReflection")]));
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("n", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\n        pos -= u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".p;\n        float glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " = dot(u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " < 0. || u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.z < glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ") {\n          float ref = abs(floor(glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " / u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.z));\n          invNum += ref;\n          pos -= u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.xy * (glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " - mod(glideInv";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ", u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.w/2.));\n          if(mod(ref, 2.0) == 1.) {\n              vec2 nGlide";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " = vec2(-u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.y, u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".normal.x);\n              float dGlide";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " = dot(pos, nGlide";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ");\n              pos -= 2.0 *  dGlide";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += " * nGlide";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ";\n          }\n          inFund = false;\n        }\n        pos += u_glideReflection";
output += runtime.suppressValue(t_44, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_47 = (lineno = 151, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCrossingInversions")]));
if(t_47) {t_47 = runtime.fromIterator(t_47);
var t_46 = t_47.length;
for(var t_45=0; t_45 < t_47.length; t_45++) {
var t_48 = t_47[t_45];
frame.set("n", t_48);
frame.set("loop.index", t_45 + 1);
frame.set("loop.index0", t_45);
frame.set("loop.revindex", t_46 - t_45);
frame.set("loop.revindex0", t_46 - t_45 - 1);
frame.set("loop.first", t_45 === 0);
frame.set("loop.last", t_45 === t_46 - 1);
frame.set("loop.length", t_46);
output += "\n        pos -= u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".p;\n        float dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " = dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".normal.xy);\n        invNum += (dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " < 0.) ? 1. : 0.;\n        inFund = (dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ") * u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".normal.xy;\n        pos += u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".p;\n\n        pos -= u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".p;\n        dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " = dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".normal.zw);\n        invNum += (dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " < 0.) ? 1. : 0.;\n        inFund = (dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dCI";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ") * u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".normal.zw;\n        pos += u_crossingInversions";
output += runtime.suppressValue(t_48, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_51 = (lineno = 167, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numRotation")]));
if(t_51) {t_51 = runtime.fromIterator(t_51);
var t_50 = t_51.length;
for(var t_49=0; t_49 < t_51.length; t_49++) {
var t_52 = t_51[t_49];
frame.set("n", t_52);
frame.set("loop.index", t_49 + 1);
frame.set("loop.index0", t_49);
frame.set("loop.revindex", t_50 - t_49);
frame.set("loop.revindex0", t_50 - t_49 - 1);
frame.set("loop.first", t_49 === 0);
frame.set("loop.last", t_49 === t_50 - 1);
frame.set("loop.length", t_50);
output += "\n        pos -= u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".p;\n        float dRot1";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " = dot(pos, u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".normal.xy);\n        float dRot2";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " = dot(pos, u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".normal.zw);\n        if(dRot1";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " < 0. || dRot2";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " < 0.) {\n            invNum++;\n            inFund = false;\n            mat2 rotateM";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " = mat2(cos(u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".rotationRad),\n                                       -sin(u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".rotationRad),\n                                       sin(u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".rotationRad),\n                                       cos(u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".rotationRad));\n            pos = rotateM";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += " * pos;\n        }\n        pos += u_rotation";
output += runtime.suppressValue(t_52, env.opts.autoescape);
output += ".p;\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_55 = (lineno = 183, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numTwoCircles")]));
if(t_55) {t_55 = runtime.fromIterator(t_55);
var t_54 = t_55.length;
for(var t_53=0; t_53 < t_55.length; t_53++) {
var t_56 = t_55[t_53];
frame.set("n", t_56);
frame.set("loop.index", t_53 + 1);
frame.set("loop.index0", t_53);
frame.set("loop.revindex", t_54 - t_53);
frame.set("loop.revindex0", t_54 - t_53 - 1);
frame.set("loop.first", t_53 === 0);
frame.set("loop.last", t_53 === t_54 - 1);
frame.set("loop.length", t_54);
output += "\n        if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1.xy) < u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1.z){\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c2);\n\n            inFund = false;\n       }else if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1d.xy) >= u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_56, env.opts.autoescape);
output += ".c1);\n\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_59 = (lineno = 197, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numLoxodromic")]));
if(t_59) {t_59 = runtime.fromIterator(t_59);
var t_58 = t_59.length;
for(var t_57=0; t_57 < t_59.length; t_57++) {
var t_60 = t_59[t_57];
frame.set("n", t_60);
frame.set("loop.index", t_57 + 1);
frame.set("loop.index0", t_57);
frame.set("loop.revindex", t_58 - t_57);
frame.set("loop.revindex0", t_58 - t_57 - 1);
frame.set("loop.first", t_57 === 0);
frame.set("loop.last", t_57 === t_58 - 1);
frame.set("loop.length", t_58);
output += "\n        if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.xy) < u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.z){\n            pos -= u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.xy;\n            pos -= 2.0 * dot(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".line.zw) * u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".line.zw;\n            pos += u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.xy;\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c3);\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c2);\n\n            inFund = false;\n       }else if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1d.xy) >= u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1);\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c3);\n            pos -= u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.xy;\n            pos -= 2.0 * dot(pos, u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".line.zw) * u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".line.zw;\n            pos += u_loxodromic";
output += runtime.suppressValue(t_60, env.opts.autoescape);
output += ".c1.xy;\n\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_63 = (lineno = 222, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numScaling")]));
if(t_63) {t_63 = runtime.fromIterator(t_63);
var t_62 = t_63.length;
for(var t_61=0; t_61 < t_63.length; t_61++) {
var t_64 = t_63[t_61];
frame.set("n", t_64);
frame.set("loop.index", t_61 + 1);
frame.set("loop.index0", t_61);
frame.set("loop.revindex", t_62 - t_61);
frame.set("loop.revindex0", t_62 - t_61 - 1);
frame.set("loop.first", t_61 === 0);
frame.set("loop.last", t_61 === t_62 - 1);
frame.set("loop.length", t_62);
output += "\n        if(distance(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1.xy) < u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1.z){\n            pos -= u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.zw) * u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.xy;\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line2.zw) * u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line2.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2.xy;\n\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2);\n\n            inFund = false;\n       }else if(distance(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1d.xy) >= u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c1);\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line2.zw) * u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line2.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".c2.xy;\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.zw) * u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_64, env.opts.autoescape);
output += ".line1.xy;\n\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        if (inFund) break;\n    }\n\n    col = mix(u_backgroundColor, vec4(computeColor(invNum), 1), u_isRenderingGenerator);\n    return (invNum == 0.) ? false : true;\n}\n\nbool renderOrbit(vec2 pos, out vec3 col, int numOrbit){\n    col = vec3(0);\n    for(int i = 0; i < numOrbit; i++) {\n        if(distance(pos, g_orbitPoints[i]) < .01) {\n            col = vec3(0, 0, 1);\n            return true;\n        }\n        if(i > 0) {\n            vec2 p1 = g_orbitPoints[i - 1];\n            vec2 p2 = g_orbitPoints[i];\n            vec2 v = p2 - p1;\n            vec2 n = normalize(vec2(-v.y, v.x));\n            vec2 posP1 = pos - p1;\n            vec2 posP2 = pos - p2;\n            if(dot(posP1, posP2) < 0. &&\n               abs(dot(n, posP1)) < .001) {\n                col = vec3(0, 1, 1);\n                return true;\n            }\n        }\n    }\n    return false;\n}\n\nint computeOrbit(vec2 pos) {\n    bool inFund = true;\n    vec4 c = vec4(0);\n    int orbitNum = 0;\n    g_orbitPoints[orbitNum] = pos;\n    orbitNum++;\n    for (int i = 1; i < NUM_ORBIT_POINTS; i++) {\n        inFund = true;\n\n        ";
frame = frame.push();
var t_67 = (lineno = 292, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircle")]));
if(t_67) {t_67 = runtime.fromIterator(t_67);
var t_66 = t_67.length;
for(var t_65=0; t_65 < t_67.length; t_65++) {
var t_68 = t_67[t_65];
frame.set("n", t_68);
frame.set("loop.index", t_65 + 1);
frame.set("loop.index0", t_65);
frame.set("loop.revindex", t_66 - t_65);
frame.set("loop.revindex0", t_66 - t_65 - 1);
frame.set("loop.first", t_65 === 0);
frame.set("loop.last", t_65 === t_66 - 1);
frame.set("loop.length", t_66);
output += "\n        if(distance(pos, u_circle";
output += runtime.suppressValue(t_68, env.opts.autoescape);
output += ".centerAndRadius.xy) < u_circle";
output += runtime.suppressValue(t_68, env.opts.autoescape);
output += ".centerAndRadius.z){\n            pos = circleInvert(pos, u_circle";
output += runtime.suppressValue(t_68, env.opts.autoescape);
output += ".centerAndRadius);\n            g_orbitPoints[orbitNum] = pos;\n            inFund = false;\n            orbitNum++;\n            continue;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_71 = (lineno = 302, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircleFromPoints")]));
if(t_71) {t_71 = runtime.fromIterator(t_71);
var t_70 = t_71.length;
for(var t_69=0; t_69 < t_71.length; t_69++) {
var t_72 = t_71[t_69];
frame.set("n", t_72);
frame.set("loop.index", t_69 + 1);
frame.set("loop.index0", t_69);
frame.set("loop.revindex", t_70 - t_69);
frame.set("loop.revindex0", t_70 - t_69 - 1);
frame.set("loop.first", t_69 === 0);
frame.set("loop.last", t_69 === t_70 - 1);
frame.set("loop.length", t_70);
output += "\n        if(distance(pos, u_circleFromPoints";
output += runtime.suppressValue(t_72, env.opts.autoescape);
output += ".centerAndRadius.xy) < u_circleFromPoints";
output += runtime.suppressValue(t_72, env.opts.autoescape);
output += ".centerAndRadius.z){\n            pos = circleInvert(pos, u_circleFromPoints";
output += runtime.suppressValue(t_72, env.opts.autoescape);
output += ".centerAndRadius);\n            g_orbitPoints[orbitNum] = pos;\n            inFund = false;\n            orbitNum++;\n            continue;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_75 = (lineno = 312, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numHalfPlane")]));
if(t_75) {t_75 = runtime.fromIterator(t_75);
var t_74 = t_75.length;
for(var t_73=0; t_73 < t_75.length; t_73++) {
var t_76 = t_75[t_73];
frame.set("n", t_76);
frame.set("loop.index", t_73 + 1);
frame.set("loop.index0", t_73);
frame.set("loop.revindex", t_74 - t_73);
frame.set("loop.revindex0", t_74 - t_73 - 1);
frame.set("loop.first", t_73 === 0);
frame.set("loop.last", t_73 === t_74 - 1);
frame.set("loop.length", t_74);
output += "\n        pos -= u_halfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += ".p;\n        float dHalfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += " = dot(pos, u_halfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += ".normal.xy);\n        //orbitNum += (dHalfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += " < 0.) ? 1. : 0.;\n        inFund = (dHalfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dHalfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += ") * u_halfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += ".normal.xy;\n        pos += u_halfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += ".p;\n        if (dHalfPlane";
output += runtime.suppressValue(t_76, env.opts.autoescape);
output += " < 0.){\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_79 = (lineno = 325, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelTranslation")]));
if(t_79) {t_79 = runtime.fromIterator(t_79);
var t_78 = t_79.length;
for(var t_77=0; t_77 < t_79.length; t_77++) {
var t_80 = t_79[t_77];
frame.set("n", t_80);
frame.set("loop.index", t_77 + 1);
frame.set("loop.index0", t_77);
frame.set("loop.revindex", t_78 - t_77);
frame.set("loop.revindex0", t_78 - t_77 - 1);
frame.set("loop.first", t_77 === 0);
frame.set("loop.last", t_77 === t_78 - 1);
frame.set("loop.length", t_78);
output += "\n        pos -= u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".p;\n        float hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += " = dot(u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += " < 0. || u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.z < hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ") {\n            //orbitNum += abs(floor(hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += " / u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.z));\n            pos -= u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.xy * (hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += " - mod(hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ", u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.w/2.));\n\n            inFund = false;\n        }\n        pos += u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".p;\n        if(hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += " < 0. || u_translate";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ".normal.z < hpd";
output += runtime.suppressValue(t_80, env.opts.autoescape);
output += ") {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_83 = (lineno = 341, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelInversions")]));
if(t_83) {t_83 = runtime.fromIterator(t_83);
var t_82 = t_83.length;
for(var t_81=0; t_81 < t_83.length; t_81++) {
var t_84 = t_83[t_81];
frame.set("n", t_84);
frame.set("loop.index", t_81 + 1);
frame.set("loop.index0", t_81);
frame.set("loop.revindex", t_82 - t_81);
frame.set("loop.revindex0", t_82 - t_81 - 1);
frame.set("loop.first", t_81 === 0);
frame.set("loop.last", t_81 === t_82 - 1);
frame.set("loop.length", t_82);
output += "\n        pos -= u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".p;\n        float hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " = dot(u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " < 0. || u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.z < hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ") {\n            //orbitNum += abs(floor(hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " / u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.z));\n            pos -= u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy * (hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " - mod(hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ", u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.w));\n            pos -= u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.z;\n            hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " = dot(pos, u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy);\n            pos -= 2.0 * max(0., hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ") * u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy;\n            pos += u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.z;\n\n            inFund = false;\n        }\n        pos += u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".p;\n        if(hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += " < 0. || u_parallelInversions";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ".normal.z < hpdInv";
output += runtime.suppressValue(t_84, env.opts.autoescape);
output += ") {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_87 = (lineno = 361, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numGlideReflection")]));
if(t_87) {t_87 = runtime.fromIterator(t_87);
var t_86 = t_87.length;
for(var t_85=0; t_85 < t_87.length; t_85++) {
var t_88 = t_87[t_85];
frame.set("n", t_88);
frame.set("loop.index", t_85 + 1);
frame.set("loop.index0", t_85);
frame.set("loop.revindex", t_86 - t_85);
frame.set("loop.revindex0", t_86 - t_85 - 1);
frame.set("loop.first", t_85 === 0);
frame.set("loop.last", t_85 === t_86 - 1);
frame.set("loop.length", t_86);
output += "\n        pos -= u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".p;\n        float glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " = dot(u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " < 0. || u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.z < glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ") {\n          float ref = abs(floor(glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " / u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.z));\n          //orbitNum += ref;\n          pos -= u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.xy * (glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " - mod(glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ", u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.w/2.));\n          if(mod(ref, 2.0) == 1.) {\n              vec2 nGlide";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " = vec2(-u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.y, u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.x);\n              float dGlide";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " = dot(pos, nGlide";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ");\n              pos -= 2.0 *  dGlide";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " * nGlide";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ";\n          }\n          inFund = false;\n        }\n        pos += u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".p;\n        if(glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += " < 0. || u_glideReflection";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ".normal.z < glideInv";
output += runtime.suppressValue(t_88, env.opts.autoescape);
output += ") {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_91 = (lineno = 382, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCrossingInversions")]));
if(t_91) {t_91 = runtime.fromIterator(t_91);
var t_90 = t_91.length;
for(var t_89=0; t_89 < t_91.length; t_89++) {
var t_92 = t_91[t_89];
frame.set("n", t_92);
frame.set("loop.index", t_89 + 1);
frame.set("loop.index0", t_89);
frame.set("loop.revindex", t_90 - t_89);
frame.set("loop.revindex0", t_90 - t_89 - 1);
frame.set("loop.first", t_89 === 0);
frame.set("loop.last", t_89 === t_90 - 1);
frame.set("loop.length", t_90);
output += "\n        pos -= u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".p;\n        float dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " = dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".normal.xy);\n        //orbitNum += (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0.) ? 1 : 0;\n        inFund = (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ") * u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".normal.xy;\n        pos += u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".p;\n        if (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0.) {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n\n        pos -= u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".p;\n        dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " = dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".normal.zw);\n        //orbitNum += (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0.) ? 1 : 0;\n        inFund = (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0. ) ? false : inFund;\n        pos -= 2.0 * min(0., dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ") * u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".normal.zw;\n        pos += u_crossingInversions";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += ".p;\n        if (dCI";
output += runtime.suppressValue(t_92, env.opts.autoescape);
output += " < 0.) {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_95 = (lineno = 406, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numRotation")]));
if(t_95) {t_95 = runtime.fromIterator(t_95);
var t_94 = t_95.length;
for(var t_93=0; t_93 < t_95.length; t_93++) {
var t_96 = t_95[t_93];
frame.set("n", t_96);
frame.set("loop.index", t_93 + 1);
frame.set("loop.index0", t_93);
frame.set("loop.revindex", t_94 - t_93);
frame.set("loop.revindex0", t_94 - t_93 - 1);
frame.set("loop.first", t_93 === 0);
frame.set("loop.last", t_93 === t_94 - 1);
frame.set("loop.length", t_94);
output += "\n        pos -= u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".p;\n        float dRot1";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " = dot(pos, u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".normal.xy);\n        float dRot2";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " = dot(pos, u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".normal.zw);\n        //orbitNum += (dRot1";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0. || dRot2";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0.) ? 1 : 0;\n        if(dRot1";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0. || dRot2";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0.) {\n            inFund = false;\n            mat2 rotateM";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " = mat2(cos(u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".rotationRad),\n                                       -sin(u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".rotationRad),\n                                       sin(u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".rotationRad),\n                                       cos(u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".rotationRad));\n            pos = rotateM";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " * pos;\n        }\n        pos += u_rotation";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += ".p;\n        if(dRot1";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0. || dRot2";
output += runtime.suppressValue(t_96, env.opts.autoescape);
output += " < 0.) {\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_99 = (lineno = 426, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numTwoCircles")]));
if(t_99) {t_99 = runtime.fromIterator(t_99);
var t_98 = t_99.length;
for(var t_97=0; t_97 < t_99.length; t_97++) {
var t_100 = t_99[t_97];
frame.set("n", t_100);
frame.set("loop.index", t_97 + 1);
frame.set("loop.index0", t_97);
frame.set("loop.revindex", t_98 - t_97);
frame.set("loop.revindex0", t_98 - t_97 - 1);
frame.set("loop.first", t_97 === 0);
frame.set("loop.last", t_97 === t_98 - 1);
frame.set("loop.length", t_98);
output += "\n        if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1.xy) < u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1.z){\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c2);\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n       }else if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1d.xy) >= u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_hyperbolic";
output += runtime.suppressValue(t_100, env.opts.autoescape);
output += ".c1);\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_103 = (lineno = 442, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numLoxodromic")]));
if(t_103) {t_103 = runtime.fromIterator(t_103);
var t_102 = t_103.length;
for(var t_101=0; t_101 < t_103.length; t_101++) {
var t_104 = t_103[t_101];
frame.set("n", t_104);
frame.set("loop.index", t_101 + 1);
frame.set("loop.index0", t_101);
frame.set("loop.revindex", t_102 - t_101);
frame.set("loop.revindex0", t_102 - t_101 - 1);
frame.set("loop.first", t_101 === 0);
frame.set("loop.last", t_101 === t_102 - 1);
frame.set("loop.length", t_102);
output += "\n        if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.xy) < u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.z){\n            pos -= u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.xy;\n            pos -= 2.0 * dot(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".line.zw) * u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".line.zw;\n            pos += u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.xy;\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c3);\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c2);\n\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n       }else if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1d.xy) >= u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1);\n\n            pos = circleInvert(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c3);\n            pos -= u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.xy;\n            pos -= 2.0 * dot(pos, u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".line.zw) * u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".line.zw;\n            pos += u_loxodromic";
output += runtime.suppressValue(t_104, env.opts.autoescape);
output += ".c1.xy;\n\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        ";
frame = frame.push();
var t_107 = (lineno = 471, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numScaling")]));
if(t_107) {t_107 = runtime.fromIterator(t_107);
var t_106 = t_107.length;
for(var t_105=0; t_105 < t_107.length; t_105++) {
var t_108 = t_107[t_105];
frame.set("n", t_108);
frame.set("loop.index", t_105 + 1);
frame.set("loop.index0", t_105);
frame.set("loop.revindex", t_106 - t_105);
frame.set("loop.revindex0", t_106 - t_105 - 1);
frame.set("loop.first", t_105 === 0);
frame.set("loop.last", t_105 === t_106 - 1);
frame.set("loop.length", t_106);
output += "\n        if(distance(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1.xy) < u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1.z){\n            pos -= u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.zw) * u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.xy;\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line2.zw) * u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line2.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2.xy;\n\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1);\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2);\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n       }else if(distance(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1d.xy) >= u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1d.z){\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2);\n            pos = circleInvert(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c1);\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line2.zw) * u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line2.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".c2.xy;\n\n            pos -= u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.xy;\n            pos -= 2.0 * dot(pos, u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.zw) * u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.zw;\n            pos += u_scaling";
output += runtime.suppressValue(t_108, env.opts.autoescape);
output += ".line1.xy;\n            g_orbitPoints[orbitNum] = pos;\n            orbitNum++;\n            inFund = false;\n        }\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        if (inFund) break;\n    }\n    return orbitNum;\n}\n\nbool renderEdgeOfOrbitSeed(vec2 pos, out vec4 color) {\n    color = u_backgroundColor;\n\n    ";
frame = frame.push();
var t_111 = (lineno = 511, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCanvasSeed")]));
if(t_111) {t_111 = runtime.fromIterator(t_111);
var t_110 = t_111.length;
for(var t_109=0; t_109 < t_111.length; t_109++) {
var t_112 = t_111[t_109];
frame.set("no", t_112);
frame.set("loop.index", t_109 + 1);
frame.set("loop.index0", t_109);
frame.set("loop.revindex", t_110 - t_109);
frame.set("loop.revindex0", t_110 - t_109 - 1);
frame.set("loop.first", t_109 === 0);
frame.set("loop.last", t_109 === t_110 - 1);
frame.set("loop.length", t_110);
output += "\n    if(u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".selected) {\n        vec2 canvasUV";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += " = (pos - u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".corner) / u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".size;\n        if(0. < canvasUV";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".x && canvasUV";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".x < 1. &&\n           0. < canvasUV";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".y && canvasUV";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".y < 1. &&\n           (pos.x < u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".ui.x || u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".ui.z < pos.x ||\n            pos.y < u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".ui.y || u_canvasSeed";
output += runtime.suppressValue(t_112, env.opts.autoescape);
output += ".ui.w < pos.y)) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_115 = (lineno = 524, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numOrbitSeed")]));
if(t_115) {t_115 = runtime.fromIterator(t_115);
var t_114 = t_115.length;
for(var t_113=0; t_113 < t_115.length; t_113++) {
var t_116 = t_115[t_113];
frame.set("no", t_116);
frame.set("loop.index", t_113 + 1);
frame.set("loop.index0", t_113);
frame.set("loop.revindex", t_114 - t_113);
frame.set("loop.revindex0", t_114 - t_113 - 1);
frame.set("loop.first", t_113 === 0);
frame.set("loop.last", t_113 === t_114 - 1);
frame.set("loop.length", t_114);
output += "\n    if(u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".selected) {\n        vec2 uvOrbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += " = (pos - u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".corner) / u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".size;\n        if(0. < uvOrbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".x && uvOrbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".x < 1. &&\n           0. < uvOrbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".y && uvOrbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".y < 1. &&\n           (pos.x < u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".ui.x || u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".ui.z < pos.x ||\n            pos.y < u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".ui.y || u_orbitSeed";
output += runtime.suppressValue(t_116, env.opts.autoescape);
output += ".ui.w < pos.y)) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_119 = (lineno = 537, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numVideoOrbit")]));
if(t_119) {t_119 = runtime.fromIterator(t_119);
var t_118 = t_119.length;
for(var t_117=0; t_117 < t_119.length; t_117++) {
var t_120 = t_119[t_117];
frame.set("no", t_120);
frame.set("loop.index", t_117 + 1);
frame.set("loop.index0", t_117);
frame.set("loop.revindex", t_118 - t_117);
frame.set("loop.revindex0", t_118 - t_117 - 1);
frame.set("loop.first", t_117 === 0);
frame.set("loop.last", t_117 === t_118 - 1);
frame.set("loop.length", t_118);
output += "\n    if(u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".selected) {\n        vec2 videoUV";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += " = (pos - u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".corner) / u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".size;\n        if(0. < videoUV";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".x && videoUV";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".x < 1. &&\n           0. < videoUV";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".y && videoUV";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".y < 1. &&\n           (pos.x < u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".ui.x || u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".ui.z < pos.x ||\n            pos.y < u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".ui.y || u_videoOrbit";
output += runtime.suppressValue(t_120, env.opts.autoescape);
output += ".ui.w < pos.y)) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    return false;\n}\n\nbool renderUI(vec2 pos, out vec4 color) {\n    color = u_backgroundColor;\n\n    ";
frame = frame.push();
var t_123 = (lineno = 556, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numPoint")]));
if(t_123) {t_123 = runtime.fromIterator(t_123);
var t_122 = t_123.length;
for(var t_121=0; t_121 < t_123.length; t_121++) {
var t_124 = t_123[t_121];
frame.set("n", t_124);
frame.set("loop.index", t_121 + 1);
frame.set("loop.index0", t_121);
frame.set("loop.revindex", t_122 - t_121);
frame.set("loop.revindex0", t_122 - t_121 - 1);
frame.set("loop.first", t_121 === 0);
frame.set("loop.last", t_121 === t_122 - 1);
frame.set("loop.length", t_122);
output += "\n    if(distance(pos, u_point";
output += runtime.suppressValue(t_124, env.opts.autoescape);
output += ".xy) < u_point";
output += runtime.suppressValue(t_124, env.opts.autoescape);
output += ".z){\n        color = vec4(BLUE, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    float dist;\n    ";
frame = frame.push();
var t_127 = (lineno = 564, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCircle")]));
if(t_127) {t_127 = runtime.fromIterator(t_127);
var t_126 = t_127.length;
for(var t_125=0; t_125 < t_127.length; t_125++) {
var t_128 = t_127[t_125];
frame.set("n", t_128);
frame.set("loop.index", t_125 + 1);
frame.set("loop.index0", t_125);
frame.set("loop.revindex", t_126 - t_125);
frame.set("loop.revindex0", t_126 - t_125 - 1);
frame.set("loop.first", t_125 === 0);
frame.set("loop.last", t_125 === t_126 - 1);
frame.set("loop.length", t_126);
output += "\n    // boundary of circle\n    if(u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".selected){\n        dist = u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".centerAndRadius.z - distance(pos, u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".centerAndRadius.xy);\n        if(0. < dist && dist < u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".ui){\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // Render the axis\n        // if(u_isPressingShift && abs(pos.y - u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".centerAndRadius.y) < u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".ui/4.) {\n        //     color = WHITE;\n        //     return true;\n        // } else if(u_isPressingCtrl && abs(pos.x - u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".centerAndRadius.x) < u_circle";
output += runtime.suppressValue(t_128, env.opts.autoescape);
output += ".ui/4.) {\n        //     color = WHITE;\n        //     return true;\n        // }\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n\n    ";
frame = frame.push();
var t_131 = (lineno = 584, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numHalfPlane")]));
if(t_131) {t_131 = runtime.fromIterator(t_131);
var t_130 = t_131.length;
for(var t_129=0; t_129 < t_131.length; t_129++) {
var t_132 = t_131[t_129];
frame.set("n", t_132);
frame.set("loop.index", t_129 + 1);
frame.set("loop.index0", t_129);
frame.set("loop.revindex", t_130 - t_129);
frame.set("loop.revindex0", t_130 - t_129 - 1);
frame.set("loop.first", t_129 === 0);
frame.set("loop.last", t_129 === t_130 - 1);
frame.set("loop.length", t_130);
output += "\n    if(u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".selected) {\n        // normal point\n        if(distance(pos, u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p + u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.xy * u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.z) < u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // normal point Boundary\n        if(distance(pos, u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p + u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.xy * u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.z) < u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point p\n        if(distance(pos, u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p) < u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        // point p Boundary\n        if(distance(pos, u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p) < u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // ring\n        if(abs(distance(pos, u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p) - u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.z) < u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // line\n        dist = dot(pos - u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".p , u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.xy);\n        if(-u_halfPlane";
output += runtime.suppressValue(t_132, env.opts.autoescape);
output += ".normal.w < dist && dist < 0.) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_135 = (lineno = 620, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelTranslation")]));
if(t_135) {t_135 = runtime.fromIterator(t_135);
var t_134 = t_135.length;
for(var t_133=0; t_133 < t_135.length; t_133++) {
var t_136 = t_135[t_133];
frame.set("n", t_136);
frame.set("loop.index", t_133 + 1);
frame.set("loop.index0", t_133);
frame.set("loop.revindex", t_134 - t_133);
frame.set("loop.revindex0", t_134 - t_133 - 1);
frame.set("loop.first", t_133 === 0);
frame.set("loop.last", t_133 === t_134 - 1);
frame.set("loop.length", t_134);
output += "\n    if(u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".selected){\n        // normal point\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p + u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy * u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.x) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // normal point Boundary\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p + u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy * u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.x) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // ring\n        if(abs(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p) - u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.x) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point p\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        // point p Boundary\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point on hp2\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p + u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy * u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.z) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(GREEN, 1);\n            return true;\n        }\n        // point on hp2 Boundary\n        if(distance(pos, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p + u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy * u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.z) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // boundary\n        dist = dot(pos - u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p, - u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        dist = -dot(pos - (u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p + u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy * u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.z),\n                    - u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        // line\n        pos -= u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p;\n        float hpd";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += " = dot(u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpd";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += " > 0. && u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.z > hpd";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += " &&\n           abs(dot(pos, vec2(-u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.y, u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".normal.x))) < u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        pos += u_translate";
output += runtime.suppressValue(t_136, env.opts.autoescape);
output += ".p;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_139 = (lineno = 683, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numParallelInversions")]));
if(t_139) {t_139 = runtime.fromIterator(t_139);
var t_138 = t_139.length;
for(var t_137=0; t_137 < t_139.length; t_137++) {
var t_140 = t_139[t_137];
frame.set("n", t_140);
frame.set("loop.index", t_137 + 1);
frame.set("loop.index0", t_137);
frame.set("loop.revindex", t_138 - t_137);
frame.set("loop.revindex0", t_138 - t_137 - 1);
frame.set("loop.first", t_137 === 0);
frame.set("loop.last", t_137 === t_138 - 1);
frame.set("loop.length", t_138);
output += "\n    if(u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".selected){\n\n        // normal point\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p + u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.x) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // normal point boundary\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p + u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.x) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // ring\n        if(abs(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p) - u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.x) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point p\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        // point p boundary\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point on hp2\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p + u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.z) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(GREEN, 1);\n            return true;\n        }\n        // point on hp2 boundary\n        if(distance(pos, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p + u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.z) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // boundary\n        dist = dot(pos - u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p, - u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        dist = -dot(pos - (u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p + u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy * u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.z),\n                   - u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        // line\n        pos -= u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p;\n        float hpdInv";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += " = dot(u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpdInv";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += " > 0. && u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.z > hpdInv";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += " &&\n           abs(dot(pos, vec2(-u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.y, u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".normal.x))) < u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        pos += u_parallelInversions";
output += runtime.suppressValue(t_140, env.opts.autoescape);
output += ".p;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_143 = (lineno = 747, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numGlideReflection")]));
if(t_143) {t_143 = runtime.fromIterator(t_143);
var t_142 = t_143.length;
for(var t_141=0; t_141 < t_143.length; t_141++) {
var t_144 = t_143[t_141];
frame.set("n", t_144);
frame.set("loop.index", t_141 + 1);
frame.set("loop.index0", t_141);
frame.set("loop.revindex", t_142 - t_141);
frame.set("loop.revindex0", t_142 - t_141 - 1);
frame.set("loop.first", t_141 === 0);
frame.set("loop.last", t_141 === t_142 - 1);
frame.set("loop.length", t_142);
output += "\n    if(u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".selected){\n        // normal point\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p + u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy * u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.x) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // normal point boundary\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p + u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy * u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.x) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // ring\n        if(abs(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p) - u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.x) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point p\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        // point p boundary\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // point on hp2\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p + u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy * u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.z) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(GREEN, 1);\n            return true;\n        }\n        // point on hp2 boundary\n        if(distance(pos, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p + u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy * u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.z) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // boundary\n        dist = dot(pos - u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p, - u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        dist = -dot(pos - (u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p + u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy * u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.z),\n                   - u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy);\n        if(0. < dist && dist < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        // line\n        pos -= u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p;\n        float hpdGlide";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += " = dot(u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(hpdGlide";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += " > 0. && u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.z > hpdGlide";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += " &&\n           abs(dot(pos, vec2(-u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.y, u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".normal.x))) < u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        pos += u_glideReflection";
output += runtime.suppressValue(t_144, env.opts.autoescape);
output += ".p;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_147 = (lineno = 810, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numCrossingInversions")]));
if(t_147) {t_147 = runtime.fromIterator(t_147);
var t_146 = t_147.length;
for(var t_145=0; t_145 < t_147.length; t_145++) {
var t_148 = t_147[t_145];
frame.set("n", t_148);
frame.set("loop.index", t_145 + 1);
frame.set("loop.index0", t_145);
frame.set("loop.revindex", t_146 - t_145);
frame.set("loop.revindex0", t_146 - t_145 - 1);
frame.set("loop.first", t_145 === 0);
frame.set("loop.last", t_145 === t_146 - 1);
frame.set("loop.length", t_146);
output += "\n    if(u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".selected) {\n        // point p\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        // point p boundary\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".boundaryPoint.zw) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(GREEN, 1);\n            return true;\n        }\n        // boundary\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".boundaryPoint.zw) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".boundaryPoint.xy) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // boundary\n        if(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".boundaryPoint.xy) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // line\n        pos -= u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p;\n        dist = dot(-u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(0. < dist && dist < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        dist = dot(-u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".normal.zw, pos);\n        if(0. < dist && dist < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        // ring\n        if(dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".normal.xy) > 0. &&\n           dot(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".normal.zw) > 0. &&\n           abs(distance(pos, u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p - u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p) - u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.x) < u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        pos += u_crossingInversions";
output += runtime.suppressValue(t_148, env.opts.autoescape);
output += ".p;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_151 = (lineno = 864, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numRotation")]));
if(t_151) {t_151 = runtime.fromIterator(t_151);
var t_150 = t_151.length;
for(var t_149=0; t_149 < t_151.length; t_149++) {
var t_152 = t_151[t_149];
frame.set("n", t_152);
frame.set("loop.index", t_149 + 1);
frame.set("loop.index0", t_149);
frame.set("loop.revindex", t_150 - t_149);
frame.set("loop.revindex0", t_150 - t_149 - 1);
frame.set("loop.first", t_149 === 0);
frame.set("loop.last", t_149 === t_150 - 1);
frame.set("loop.length", t_150);
output += "\n    if(u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".selected) {\n        // point p\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(LIGHT_BLUE, 1);\n            return true;\n        }\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".boundaryPoint.zw) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(GREEN, 1);\n            return true;\n        }\n        // boundary\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".boundaryPoint.zw) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".boundaryPoint.xy) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(PINK, 1);\n            return true;\n        }\n        // boundary\n        if(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".boundaryPoint.xy) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y * 1.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        // line\n        pos -= u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p;\n        dist = dot(-u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".normal.xy, pos);\n        if(0. < dist && dist < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        dist = dot(-u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".normal.zw, pos);\n        if(0. < dist && dist < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        // ring\n        if(dot(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".normal.xy) > 0. &&\n           dot(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".normal.zw) > 0. &&\n           abs(distance(pos, u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p - u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p) - u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.x) < u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".ui.y *.5) {\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n        pos += u_rotation";
output += runtime.suppressValue(t_152, env.opts.autoescape);
output += ".p;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_155 = (lineno = 917, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numLoxodromic")]));
if(t_155) {t_155 = runtime.fromIterator(t_155);
var t_154 = t_155.length;
for(var t_153=0; t_153 < t_155.length; t_153++) {
var t_156 = t_155[t_153];
frame.set("n", t_156);
frame.set("loop.index", t_153 + 1);
frame.set("loop.index0", t_153);
frame.set("loop.revindex", t_154 - t_153);
frame.set("loop.revindex0", t_154 - t_153 - 1);
frame.set("loop.first", t_153 === 0);
frame.set("loop.last", t_153 === t_154 - 1);
frame.set("loop.length", t_154);
output += "\n    // point p\n    if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_156, env.opts.autoescape);
output += ".p) < u_loxodromic";
output += runtime.suppressValue(t_156, env.opts.autoescape);
output += ".ui.x) {\n        color = vec4(PINK, 1);\n        return true;\n    }\n    // point p boundary\n    if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_156, env.opts.autoescape);
output += ".p) < u_loxodromic";
output += runtime.suppressValue(t_156, env.opts.autoescape);
output += ".ui.x * 1.5) {\n        color = vec4(u_generatorBoundaryColor, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_159 = (lineno = 930, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numScaling")]));
if(t_159) {t_159 = runtime.fromIterator(t_159);
var t_158 = t_159.length;
for(var t_157=0; t_157 < t_159.length; t_157++) {
var t_160 = t_159[t_157];
frame.set("n", t_160);
frame.set("loop.index", t_157 + 1);
frame.set("loop.index0", t_157);
frame.set("loop.revindex", t_158 - t_157);
frame.set("loop.revindex0", t_158 - t_157 - 1);
frame.set("loop.first", t_157 === 0);
frame.set("loop.last", t_157 === t_158 - 1);
frame.set("loop.length", t_158);
output += "\n    if(distance(pos, u_scaling";
output += runtime.suppressValue(t_160, env.opts.autoescape);
output += ".line2.xy) < u_scaling";
output += runtime.suppressValue(t_160, env.opts.autoescape);
output += ".ui.x) {\n        color = vec4(PINK, 1);\n        return true;\n    }\n    // boundary\n    if(distance(pos, u_scaling";
output += runtime.suppressValue(t_160, env.opts.autoescape);
output += ".line2.xy) < u_scaling";
output += runtime.suppressValue(t_160, env.opts.autoescape);
output += ".ui.x * 1.5) {\n        color = vec4(u_generatorBoundaryColor, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    return false;\n}\n\nbool renderGenerator(vec2 pos, out vec4 color) {\n    color = u_backgroundColor;\n    float dist;\n    ";
frame = frame.push();
var t_163 = (lineno = 948, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numTwoCircles")]));
if(t_163) {t_163 = runtime.fromIterator(t_163);
var t_162 = t_163.length;
for(var t_161=0; t_161 < t_163.length; t_161++) {
var t_164 = t_163[t_161];
frame.set("n", t_164);
frame.set("loop.index", t_161 + 1);
frame.set("loop.index0", t_161);
frame.set("loop.revindex", t_162 - t_161);
frame.set("loop.revindex0", t_162 - t_161 - 1);
frame.set("loop.first", t_161 === 0);
frame.set("loop.last", t_161 === t_162 - 1);
frame.set("loop.length", t_162);
output += "\n    if(u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".selected) {\n        dist = u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1.z - distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1.xy);\n        if(0. < dist && dist < u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".ui){\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        dist = u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c2.z - distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c2.xy);\n        if(0. < dist && dist < u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".ui){\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1.xy) < u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1.z) {\n        color = vec4(RED, 1);\n        return true;\n    }\n    if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c2.xy) < u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c2.z) {\n        color = vec4(GREEN, 1);\n        return true;\n    }\n    if(distance(pos, u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1d.xy) < u_hyperbolic";
output += runtime.suppressValue(t_164, env.opts.autoescape);
output += ".c1d.z) {\n        color = vec4(BLUE, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_167 = (lineno = 976, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numLoxodromic")]));
if(t_167) {t_167 = runtime.fromIterator(t_167);
var t_166 = t_167.length;
for(var t_165=0; t_165 < t_167.length; t_165++) {
var t_168 = t_167[t_165];
frame.set("n", t_168);
frame.set("loop.index", t_165 + 1);
frame.set("loop.index0", t_165);
frame.set("loop.revindex", t_166 - t_165);
frame.set("loop.revindex0", t_166 - t_165 - 1);
frame.set("loop.first", t_165 === 0);
frame.set("loop.last", t_165 === t_166 - 1);
frame.set("loop.length", t_166);
output += "\n    // line\n    if(abs(dot(pos - u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1.xy,\n               u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".line.zw)) < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".ui.y) {\n        color = vec4(u_generatorBoundaryColor, 1);\n        return true;\n    }\n    vec4 loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = vec4(0);\n    bool loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = false;\n    if (distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c3.xy) < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c3.z) {\n        loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(YELLOW, 0.5), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n        loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n    }\n    if(u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".selected) {\n        dist = u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1.z - distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1.xy);\n        if(0. < dist && dist < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".ui.z){\n            loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(WHITE, 1.), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n            loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n        }\n\n        dist = u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c2.z - distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c2.xy);\n        if(0. < dist && dist < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".ui.z){\n            loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(WHITE, 1.), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n            loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n        }\n    }\n    if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1.xy) < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1.z) {\n        loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(RED, 1.), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n        loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n    }else if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c2.xy) < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c2.z) {\n        loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(GREEN, 1.), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n        loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n    }else if(distance(pos, u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1d.xy) < u_loxodromic";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".c1d.z) {\n        loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = blendCol(vec4(BLUE, 1.), loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ");\n        loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += " = true;\n    }\n    if(loxoRender";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ") {\n        color = vec4(loxoCol";
output += runtime.suppressValue(t_168, env.opts.autoescape);
output += ".rgb, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    ";
frame = frame.push();
var t_171 = (lineno = 1018, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [0,runtime.contextOrFrameLookup(context, frame, "numScaling")]));
if(t_171) {t_171 = runtime.fromIterator(t_171);
var t_170 = t_171.length;
for(var t_169=0; t_169 < t_171.length; t_169++) {
var t_172 = t_171[t_169];
frame.set("n", t_172);
frame.set("loop.index", t_169 + 1);
frame.set("loop.index0", t_169);
frame.set("loop.revindex", t_170 - t_169);
frame.set("loop.revindex0", t_170 - t_169 - 1);
frame.set("loop.first", t_169 === 0);
frame.set("loop.last", t_169 === t_170 - 1);
frame.set("loop.length", t_170);
output += "\n    if(u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".selected) {\n        dist = u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1.z - distance(pos, u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1.xy);\n        if(0. < dist && dist < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".ui.y){\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n\n        dist = u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c2.z - distance(pos, u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c2.xy);\n        if(0. < dist && dist < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".ui.y){\n            color = vec4(u_generatorBoundaryColor, 1);\n            return true;\n        }\n    }\n    if(abs(dot(pos - u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".line1.xy,\n               u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".line1.zw)) < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".ui.y) {\n        color = vec4(YELLOW, 1);\n        return true;\n    }\n    if(abs(dot(pos - u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".line2.xy,\n               u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".line2.zw)) < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".ui.y) {\n        color = vec4(u_generatorBoundaryColor, 1);\n        return true;\n    }\n    if(distance(pos, u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1.xy) < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1.z) {\n        color = vec4(RED, 1);\n        return true;\n    }\n    if(distance(pos, u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c2.xy) < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c2.z) {\n        color = vec4(GREEN, 1);\n        return true;\n    }\n    if(distance(pos, u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1d.xy) < u_scaling";
output += runtime.suppressValue(t_172, env.opts.autoescape);
output += ".c1d.z) {\n        color = vec4(BLUE, 1);\n        return true;\n    }\n    ";
;
}
}
frame = frame.pop();
output += "\n\n    return false;\n}\n\nconst float MAX_SAMPLES = 20.;\nvoid main() {\n    vec4 sum = vec4(0);\n    float ratio = u_resolution.x / u_resolution.y / 2.0;\n    for(float i = 0.; i < MAX_SAMPLES; i++){\n        vec2 position = ((gl_FragCoord.xy + rand2n(gl_FragCoord.xy, i)) / u_resolution.yy ) - vec2(ratio, 0.5);\n        position = position * u_geometry.z;\n        position += u_geometry.xy;\n\n        vec4 col = u_backgroundColor;\n        // int n = computeOrbit(u_orbitOrigin);\n        // bool line = renderOrbit(position, col, n);\n        // if(line){\n        //     sum += col;\n        //     continue;\n        // }\n\n        if(renderEdgeOfOrbitSeed(position, col)) {\n            sum += col;\n            continue;\n        }\n\n        if(u_isRenderingGenerator == 1.0 && renderUI(position, col)) {\n            sum += col;\n            continue;\n        }\n\n        bool isRendered = IIS(position, col);\n        if(isRendered){\n            sum += col;\n            continue;\n        } else {\n            if(u_isRenderingGenerator == 1.0 && renderGenerator(position, col)) {\n                sum += col;\n            } else {\n                sum += u_backgroundColor;\n            }\n            continue;\n        }\n    }\n\n    //vec3 texCol = textureLod(u_accTexture, gl_FragCoord.xy / u_resolution, 0.0).rgb;\n    outColor = sum / MAX_SAMPLES;\n}\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["src/shaders/2dShader.njk.frag"] , dependencies)