var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "underControlPanel" }, [
    _c("span", { staticClass: "parameterLabel" }, [_vm._v("scale")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c("b-input", {
          staticStyle: { width: "70px" },
          attrs: {
            placeholder: "Number",
            type: "number",
            step: "0.01",
            min: "canvasManager.canvas2d.scaleMin",
            max: "canvasManager.canavs2d.scaleMax",
          },
          on: { input: _vm.valueChanged },
          model: {
            value: _vm.canvasManager.canvas2d.scale,
            callback: function ($$v) {
              _vm.$set(_vm.canvasManager.canvas2d, "scale", _vm._n($$v))
            },
            expression: "canvasManager.canvas2d.scale",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "parameterLabel" }, [_vm._v("translate X")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c("b-input", {
          staticStyle: { width: "70px" },
          attrs: { placeholder: "Number", type: "number", step: "0.01" },
          on: { input: _vm.valueChanged },
          model: {
            value: _vm.canvasManager.canvas2d.translate.x,
            callback: function ($$v) {
              _vm.$set(_vm.canvasManager.canvas2d.translate, "x", _vm._n($$v))
            },
            expression: "canvasManager.canvas2d.translate.x",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "parameterLabel" }, [_vm._v("translate Y")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c("b-input", {
          staticStyle: { width: "70px" },
          attrs: { placeholder: "Number", type: "number", step: "0.01" },
          on: { input: _vm.valueChanged },
          model: {
            value: _vm.canvasManager.canvas2d.translate.y,
            callback: function ($$v) {
              _vm.$set(_vm.canvasManager.canvas2d.translate, "y", _vm._n($$v))
            },
            expression: "canvasManager.canvas2d.translate.y",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "parameterLabel" }, [_vm._v("Max Iterations")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c("b-input", {
          staticStyle: { width: "70px" },
          attrs: { placeholder: "Number", type: "number", step: "1", min: "0" },
          on: { input: _vm.valueChanged },
          model: {
            value: _vm.canvasManager.canvas2d.maxIterations,
            callback: function ($$v) {
              _vm.$set(_vm.canvasManager.canvas2d, "maxIterations", _vm._n($$v))
            },
            expression: "canvasManager.canvas2d.maxIterations",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.tweet } },
          [_vm._v("\n      Tweet\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.saveImage } },
          [_vm._v("\n      Save\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.clearGenerators } },
          [_vm._v("\n      Clear\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.resetGenerators } },
          [_vm._v("\n      Reset\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.saveSceneAsURL } },
          [_vm._v("\n      Copy URL\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inputContainer" },
      [
        _c(
          "b-switch",
          {
            attrs: { id: "renderGenSwitch" },
            on: { input: _vm.toggleRenderGenerator },
            model: {
              value: _vm.scene2d.isRenderingGenerator,
              callback: function ($$v) {
                _vm.$set(_vm.scene2d, "isRenderingGenerator", $$v)
              },
              expression: "scene2d.isRenderingGenerator",
            },
          },
          [_vm._v("\n      Render Generator\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }