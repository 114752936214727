var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contentParent" },
    [
      _c(
        "div",
        { staticClass: "canvasPanel" },
        [
          _c(
            "div",
            { staticClass: "canvasParent", attrs: { id: "canvasParent" } },
            [
              _c("canvas", {
                class: [_vm.mode, _vm.canvasManager.canvas2d.cursorType],
                attrs: { id: "canvas2d", tabIndex: "1000" },
              }),
              _c("br"),
            ]
          ),
          _vm._v(" "),
          _vm.display
            ? _c("underControlPanel2d", {
                attrs: {
                  scene2d: _vm.scene2d,
                  canvasManager: _vm.canvasManager,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.display
        ? _c("controlPanel2d", {
            attrs: { scene2d: _vm.scene2d, canvasManager: _vm.canvasManager },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }