var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c("ul", { staticClass: "headerLeft" }, [
        _c("div", { staticClass: "header-buttons" }, [
          _c(
            "li",
            { staticClass: "button-elem" },
            [
              _c(
                "v-link",
                { staticClass: "v-link-button", attrs: { href: "/" } },
                [_vm._v("SchottkyLink")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("header-menu", { attrs: { canvasManager: _vm.canvasManager } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }